import React from 'react'
import styled from '@emotion/styled'
import { COLORS } from '../styles'
import ClipLoader from 'react-spinners/ClipLoader'

const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.PRIMARY_DARK};
`

export const LoadingScreen = () => {
  return (
    <LoaderWrapper>
      <ClipLoader color="#ffffff" size={30} />
    </LoaderWrapper>
  )
}
