import { Avatar, Col, Row } from 'antd'
import { useLiffProfile } from 'liff'
import moment from 'moment-timezone'
import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { COLORS } from 'styles'
import { RightOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { useSelfQR } from '../../qr'
import { useQuiz } from '../../state/userInfo.state'
import { ReactComponent as CleverseLogo } from './cleverse-logo.svg'
import { ReactComponent as InfoIcon } from './info.svg'

const CleverseLogoDark = styled(CleverseLogo)`
  path {
    fill: black;
  }
`
const Card = styled.div`
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #f5f5f5;
  margin-bottom: 6px;
  background-color: ${(props) => props.color};
`
const ProfileRow = styled.div`
  display: flex;
  padding: 12px 16px;
  font-size: 14px;
  color: white;
  align-items: center;
  justify-content: space-between;
`

const CARD_COLORS = {
  GREEN: '#297D13',
  YELLOW: '#D3C226',
  ORANGE: '#E18518',
  RED: '#D22525',
  DEFAULT: '#B4B5C1',
}

const RiskCard = styled.div`
  background-color: ${COLORS.WHITE};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`

const RiskIndicator = styled.div<{ color: string; active?: boolean }>`
  background-color: ${(props) => props.color};
  height: 2px;
  width: 32px;
  border-radius: 9999px;
  margin-right: 4px;
  opacity: ${(props) => (props.active ? 1 : 0.2)};
`

const RISK_BAR_COLORS = {
  GREEN: '#56AF3E',
  YELLOW: '#F3DF27',
  ORANGE: '#FF981E',
  RED: '#F85151',
  DEFAULT: '#CDCDCD',
}

const RiskBar = ({ level }) => {
  const color = useMemo(() => {
    switch (level) {
      case 1:
        return RISK_BAR_COLORS.GREEN
      case 2:
        return RISK_BAR_COLORS.YELLOW
      case 3:
        return RISK_BAR_COLORS.ORANGE
      case 4:
        return RISK_BAR_COLORS.RED
      default:
        return RISK_BAR_COLORS.DEFAULT
    }
  }, [level])
  return (
    <div style={{ display: 'flex', alignItems: 'center', height: 8 }}>
      {Array.from({ length: 4 }).map((item, index) => (
        <RiskIndicator
          key={index}
          color={color}
          active={index < (level || 1)}
        />
      ))}
    </div>
  )
}

const RiskTitle = styled.div<{ color?: string }>`
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => props.color || 'black'};
  margin-bottom: 14px;
`

const QrImage = styled.img`
  max-width: 150px;
  max-height: 150px;
  margin: 4px 0;
`

const AvatarContainer = styled.div`
  margin-right: 8px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0;
    border-bottom: 10px solid white;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    transform: translate(-50%, 5px);
  }
`

const ScreenerButton = styled.button`
  background-color: rgb(191, 56, 48);
  border-radius: 8px;
  padding: 8px 12px;
  color: white;
  outline: none;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const RiskResult = ({ risk_level = null, assessedDate, qrCodeURL }) => {
  const riskImg = useMemo(() => {
    switch (risk_level) {
      case 1:
        return require('./state1.svg')
      case 2:
        return require('./state2.svg')
      case 3:
        return require('./state3.svg')
      case 4:
        return require('./state4.svg')
      default:
        return ''
    }
  }, [risk_level])
  return (
    <RiskCard>
      <Row
        style={{
          maxWidth: 400,
        }}
      >
        <Col span={10} style={{ display: 'flex' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: '12px 0',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderRight: '1px solid rgba(0, 0, 0, 0.14)',
              padding: '0 8px',
              marginBottom: 8,
              flex: 1,
            }}
          >
            <RiskTitle>ระดับความเสี่ยง</RiskTitle>
            <img src={riskImg} style={{ width: 80, height: 80 }} />
            <span
              style={{
                fontSize: 10,
                fontWeight: 300,
                color: 'black',
              }}
            >
              ทำแบบทดสอบเมื่อวันที่
            </span>
            <span
              style={{
                fontSize: 10,
                fontWeight: 300,
                color: 'black',
              }}
            >
              {moment(assessedDate).format('D/M/')}
              {moment(assessedDate).add(543, 'year').year()}
            </span>

            <img
              src={require('./morchana-logo.png')}
              width={60}
              height={33}
              style={{ marginTop: 8 }}
            />
          </div>
        </Col>
        <Col span={14}>
          <div
            style={{
              margin: '12px 0',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                maxWidth: 200,
              }}
            >
              <RiskTitle style={{ marginBottom: 0 }}>QR Code ของคุณ</RiskTitle>
            </div>
            <QrImage src={qrCodeURL} />
            {risk_level && <span>รายละเอียด ></span>}
          </div>
        </Col>
      </Row>
    </RiskCard>
  )
}
const NoRiskResult = () => {
  const riskImg = require('./state_unknown.svg')
  const history = useHistory()
  return (
    <RiskCard>
      <Row>
        <Row>
          <Col span={10}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                margin: '12px 0',
                alignItems: 'center',
                padding: '0 8px',
              }}
            >
              <RiskTitle style={{ display: 'flex', alignItems: 'center' }}>
                ระดับความเสี่ยง <InfoIcon style={{ marginLeft: 4 }} />
              </RiskTitle>
              <img src={riskImg} style={{ width: 80, height: 80 }} />
            </div>
          </Col>
          <Col span={14}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                margin: '12px 0',
                padding: '0 8px',
              }}
            >
              <RiskTitle color="#BF3830">ไม่มีข้อมูลความเสี่ยง</RiskTitle>
              <div style={{ fontSize: 12, color: 'black', marginBottom: 8 }}>
                ตอบแบบสอบถาม เพื่อให้ได้ข้อมูล ระดับความเสี่ยงของคุณ
              </div>
              <ScreenerButton onClick={() => history.replace('/screener')}>
                ทำแบบประเมินความเสี่ยง{' '}
                <RightOutlined style={{ marginLeft: 8 }} />
              </ScreenerButton>
            </div>
          </Col>
        </Row>
      </Row>
    </RiskCard>
  )
}

const Profile = ({ profile, risk_level }) => {
  return (
    <ProfileRow>
      <div style={{ display: 'flex' }}>
        <AvatarContainer>
          <Avatar
            size={32}
            src={profile?.pictureUrl}
            style={{ backgroundColor: '#696969' }}
          />
        </AvatarContainer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <div>{profile?.displayName}</div>
          <RiskBar level={risk_level || null} />
        </div>
      </div>
      {/* risk_level ? <CleverseLogo /> : <CleverseLogoDark /> */}
    </ProfileRow>
  )
}

export const HeroSection = () => {
  const [profile] = useLiffProfile()
  const { risk_level, latestQuiz } = useQuiz()
  const assessedDate = latestQuiz?.timestamp
  const cardColor = useMemo(() => {
    switch (risk_level) {
      case 1:
        return CARD_COLORS.GREEN
      case 2:
        return CARD_COLORS.YELLOW
      case 3:
        return CARD_COLORS.ORANGE
      case 4:
        return CARD_COLORS.RED
      default:
        return CARD_COLORS.DEFAULT
    }
  }, [risk_level])

  const { qrUrl } = useSelfQR()
  const history = useHistory()

  return (
    <Card
      className="profile-section"
      color={cardColor}
      onClick={() => history.replace(risk_level ? '/profile' : '/screener')}
    >
      <Profile profile={profile} risk_level={risk_level} />
      {risk_level ? (
        <RiskResult
          risk_level={risk_level}
          assessedDate={assessedDate}
          qrCodeURL={qrUrl}
        />
      ) : (
        <NoRiskResult />
      )}
    </Card>
  )
}
