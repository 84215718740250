import styled from '@emotion/styled'

import { SCREENER_COLOR } from './const'
import { Button } from 'antd'

export const SubmitButton = styled(Button)`
  outline: none;
  font-family: Kanit;
  background: ${SCREENER_COLOR.PRIMARY};
  color: white;
  border-radius: 4px;
  width: 100%;
  line-height: 48px;
  height: 48px;
  padding: 0;
  font-size: 16px;
  &:active {
    outline: none;
  }
`

export const BackButton = styled(Button)`
  outline: none;
  border-radius: 4px;
  width: 100%;
  line-height: 48px;
  height: 48px;
  padding: 0;
  &:active {
    outline: none;
  }
`

export const MorChanaPrimaryButton = SubmitButton
export const MorChanaSecondaryButton = styled(SubmitButton)`
  color: ${SCREENER_COLOR.PRIMARY};
  background: white;
`

export const MorChanaLinkButton = styled(SubmitButton)`
  background: transparent;
  border: none;
  box-shadow: none;
`
