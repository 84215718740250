import 'moment/locale/th'
import 'antd/dist/antd.css'

import { ApolloProvider } from '@apollo/react-hooks'
import { CachePersistor, persistCache } from 'apollo-cache-persist'
import { LoadingScreen } from 'components/Loading'
import { TabBar } from 'components/TabBar'
import moment from 'moment-timezone'
import { Profile } from 'pages/Profile'
import { default as React, useEffect, useState } from 'react'
import {
  Route,
  BrowserRouter as Router,
  Switch,
  useHistory,
} from 'react-router-dom'
import { useUserInfo } from 'state/userInfo.state'
import { useTrackVisitPage } from 'utils/tracker'

import { useLiffProfile } from './liff'
import { CovidScreener } from './pages/CovidScreener/CovidScreener'
import { CovidWorkpoint } from './pages/CovidWorkpoint'
import { DailyQuiz } from './pages/DailyQuiz'
import { DailyNotiPermission } from './pages/DailyQuiz/DailyNotiPermission'
import { Home } from './pages/Home'
import { MorchanaRedirect } from './pages/Morchana/MorChanaRedirect'
import { MorchanaRedirectBack } from './pages/Morchana/MorChanaRedirectBack'
import { Scanner } from './pages/Scanner/Scanner'
import { apolloClient, migrateState } from './state/apollo-client'
import { registerToken } from './utils/morchana'

moment.locale('th')
const purgeCache = () => {
  const persistor = new CachePersistor({
    cache: apolloClient.cache,
    storage: localStorage,
  })
  localStorage.clear()
  return persistor.purge()
}

const ClearData = () => {
  const history = useHistory()
  useEffect(() => {
    purgeCache().then(() => {
      history.replace('/')
    })
  }, [])
  return null
}

const LiffProfileLoading = ({ children }) => {
  const [profile] = useLiffProfile()
  const [, loading] = useUserInfo()
  const [loadingToken, setLoadingToken] = useState(true)
  useEffect(() => {
    if (profile) {
      registerToken().then(() => setLoadingToken(false))
    }
  }, [profile])
  if (loading || !profile || loadingToken) {
    return <LoadingScreen />
  }
  return children
}

const MainApp = () => {
  return (
    <TabBar>
      <Switch>
        <Route path="/profile">
          <Profile />
        </Route>
        <Route path="/workpoint">
          <CovidWorkpoint />
        </Route>
        <Route path="/clear">
          <ClearData />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </TabBar>
  )
}

function AppRoute() {
  useTrackVisitPage()
  return (
    <Switch>
      <Route path="/scan">
        <Scanner />
      </Route>
      <Route path="/profile/quiz">
        <LiffProfileLoading>
          <DailyQuiz />
        </LiffProfileLoading>
      </Route>
      <Route path="/noti-permission">
        <DailyNotiPermission />
      </Route>
      <Route path="/morchana/redirect">
        <MorchanaRedirect to="#" text="ระบบกำลังนำคุณไปยังหมอชนะ..." />
      </Route>
      <Route path="/morchana/redirect-back">
        <MorchanaRedirectBack />
      </Route>
      <Route path={`/screener`}>
        <LiffProfileLoading>
          <CovidScreener />
        </LiffProfileLoading>
      </Route>
      <Route>
        <LiffProfileLoading>
          <MainApp />
        </LiffProfileLoading>
      </Route>
    </Switch>
  )
}

function App() {
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      // test purpose only
      // purgeCache()
    }
    persistCache({
      cache: apolloClient.cache,
      storage: localStorage,
      // trigger: 'write',
      // debug: true,
    })
      .then(() => {
        return migrateState(apolloClient)
      })
      .then(() => {
        setLoaded(true)
      })
  }, [])
  if (!loaded) {
    return null
  }
  return (
    <ApolloProvider client={apolloClient}>
      <Router>
        <AppRoute />
      </Router>
    </ApolloProvider>
  )
}

export default App
