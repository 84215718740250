import axios from 'axios'
import { useState, useEffect } from 'react'
import Axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const MORCHANA_API_URL = process.env.REACT_APP_MORCHANA_API_URL

let registerPromise
export const registerToken = async () => {
  if (window['_registeredToken']) localStorage.getItem('token')
  if (registerPromise) {
    return registerPromise
  }
  const promise = async () => {
    const accessToken = liff.getAccessToken()
    const {
      data: { token },
    } = await axios.post(`${API_URL}/morchana/register`, null, {
      headers: {
        'Content-Type': 'application/json',
        'X-LINE-ACCESS-TOKEN': accessToken,
      },
    })
    if (token) {
      window['_registeredToken'] = true
      localStorage.setItem('token', token)
    }
    return token
  }
  registerPromise = promise().catch((err) => {
    registerPromise = null
    console.warn(err)
  })
  return registerPromise
}
export const getToken = () => {
  return localStorage.getItem('token') || tokenFromQS()
}

export const tokenFromQS = () => {
  const query = new URLSearchParams(window.location.search)
  const morchanaToken = query.get('morchanaToken')
  return morchanaToken
}

export const useMorchanaToken = (refreshNewToken: boolean = true) => {
  const [loading, setLoading] = useState(!getToken())
  const [token, setToken] = useState(getToken())
  useEffect(() => {
    const register = async () => {
      await registerToken()
      setToken(token)
      setLoading(false)
    }
    if (!token || refreshNewToken) {
      register()
    }
  }, [])

  return [token, loading]
}

export const getHeaders = () => {
  return {
    Authorization: 'Bearer ' + getToken(),
  }
}

export const scan = async (
  anonymousList: string[],
  location: { latitude: number; longitude: number; accuracy: number },
  type: 'bluetooth' | 'qrscan',
) => {
  return Axios.post(
    MORCHANA_API_URL + '/scan',
    {
      timestamp: new Date().toISOString(),
      meetWithIds: anonymousList,
      location,
      type,
    },
    {
      headers: getHeaders(),
    },
  )
}
