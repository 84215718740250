import { RightOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { Avatar, Col, Divider, Row } from 'antd'
import { useLiffProfile } from 'liff'
import _ from 'lodash'
import moment from 'moment-timezone'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { usePassQuestionaire } from 'utils/questionaire'
import { screenerMeta } from 'utils/screener-meta'
import { QRCodeSheet } from '../components/QRCodeSheet'
import { useQuiz } from '../state/userInfo.state'
import { useNotiFrequencySetting } from '../utils/quiz'
import { ScrollToTopOnMount } from '../utils/ScrollToTopOnMount'
import { MorChanaPrimaryButton } from './CovidScreener/SubmitButton'
import { HistoryList } from './DailyQuiz/HistoryList'
import { useSelfQR } from '../qr'

const FREQ_LABEL = {
  null: 'ไม่แจ้งเตือน',
  '1d': 'แจ้งเตือนทุกวัน',
  '2d': 'แจ้งเตือนทุก 2 วัน',
  '4d': 'แจ้งเตือนทุก 4 วัน',
  '7d': 'แจ้งเตือนทุกสัปดาห์',
}
const riskImgByLevel = (risk_level) => {
  switch (risk_level) {
    case 1:
      return require('./Home/state1.svg')
    case 2:
      return require('./Home/state2.svg')
    case 3:
      return require('./Home/state3.svg')
    case 4:
      return require('./Home/state4.svg')
    default:
      return ''
  }
}

const COLORS = {
  1: 'green',
  2: 'yellow',
  3: 'orange',
  4: 'red',
}

const Container = styled.div`
  padding-bottom: 35px;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background: #f5f5f9;
  width: 100%;
`
const Content = styled.div`
  .ant-divider-vertical {
    height: auto !important;
  }
`
export const Profile = () => {
  const history = useHistory()
  const [profile] = useLiffProfile()
  const isPassDailyQuestionaire = usePassQuestionaire()
  const [showQRCode, setShowQRCode] = useState(false)
  const {
    latestQuiz,
    risk_level,
    stayHomeWeekArray,
    stayHomeCombo,
    sameRiskLevelCombo,
  } = useQuiz()
  const { frequency } = useNotiFrequencySetting()
  const { qrUrl } = useSelfQR()
  const meta = screenerMeta[risk_level]
  const stayhomeIcons = useMemo(() => {
    if (!stayHomeWeekArray) {
      return null
    }
    let i = 1
    return (stayHomeWeekArray || []).map((s) => {
      if (s)
        return (
          <Col
            className="rounded-full bg-black w-8 h-8 flex justify-center items-center text-center mr-1 mb-2"
            style={{
              background: 'url("/images/stayhome-icon.svg")',
              backgroundPosition: 'center center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              color: '#2CC26A',
            }}
          >
            {i++}
          </Col>
        )
      return (
        <Col
          className="rounded-full bg-black w-8 h-8 flex justify-center items-center text-center mr-1 mb-2"
          style={{
            background: 'url("/images/stayhome-empty-icon.svg")',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            color: '#2CC26A',
          }}
        ></Col>
      )
    })
  }, [stayHomeWeekArray])
  useEffect(() => {
    if (!latestQuiz) {
      alert('ตอบแบบสอบถาม เพื่อให้ได้ข้อมูล ระดับความเสี่ยงของคุณ')
      history.replace('/screener/')
    }
  }, [])
  if (!latestQuiz) {
    return null
  }
  let { timestamp } = latestQuiz
  const assessedDate = moment(timestamp).add(543, 'year')

  const disableStayHome = true
  const smallDevice = window.innerWidth < 360

  return (
    <Container>
      <Content>
        <ScrollToTopOnMount />
        <div className="bg-white">
          <div className="flex items-center pt-4 px-4">
            <div
              className="flex justify-center items-center mr-5"
              style={{
                backgroundImage: `url('/images/profile-frame.png')`,
                backgroundColor: meta.color,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                backgroundSize: 'contain',
                width: smallDevice ? 90 : 120,
                height: smallDevice ? 90 : 120,
                borderRadius: '50%',
                border: `${smallDevice ? '4' : '5'}px solid ${meta.color}`,
              }}
            >
              <div style={{}}>
                <Avatar size={smallDevice ? 60 : 80} src={profile.pictureUrl} />
              </div>
            </div>
            <div>
              <div
                className="text-2xl font-medium mt-2 leading-none"
                style={{ color: meta.color }}
              >
                {meta.label}
              </div>
              {sameRiskLevelCombo && (
                <div className="text-lg">
                  ต่อเนื่องมา {sameRiskLevelCombo} วัน
                </div>
              )}
              <div
                className="text-xs mt-2 "
                style={{ color: '#8E8E8E', marginBottom: 4 }}
              >
                ประเมิน {assessedDate.format('D MMM YYYY HH:mm')} น.
              </div>
              <Row align="middle">
                <Col>
                  <div
                    onClick={() => history.push('/profile/quiz/history')}
                    className="text-center rounded-full py-1 px-2 mr-2"
                    style={{ color: '#808C9E', background: '#F5F5F9' }}
                  >
                    <span className="text-xs">
                      <img
                        src="https://pantruck.com/icons8/material-sharp/24/808C9E/alarm.png"
                        className="inline pr-2"
                        style={{ height: 14 }}
                      />
                      {frequency ? FREQ_LABEL[frequency] : 'แจ้งเตือน'}{' '}
                      <RightOutlined
                        style={{
                          color: '#808C9E',
                          fontSize: 12,
                          verticalAlign: 'middle',
                        }}
                      />
                    </span>
                  </div>
                </Col>
                <Col onClick={() => setShowQRCode(true)}>
                  <img
                    className="rounded bg-white"
                    style={{ maxWidth: 32, border: '1px solid #E0E0E0' }}
                    src="https://pantruck.com/icons8/windows/40/000000/qr-code.png"
                  />
                </Col>
              </Row>
            </div>
          </div>
          <Divider />
          <div className="px-4 pb-6">
            <div className="flex flex-no-wrap pb-2">
              <div className="flex-shrink-0 mr-3" style={{ flexBasis: 48 }}>
                <div>
                  <img src="/ddc-icon.png" alt="ddc" style={{ height: 48 }} />
                </div>
              </div>
              <div>
                <div className="font-medium text-base text-black">
                  คำแนะนำจากกรมควบคุมโรค
                </div>
                <p>{meta.description}</p>
              </div>
            </div>
            <MorChanaPrimaryButton
              style={{ background: '#11124D' }}
              onClick={() => {
                history.push('/screener/form')
              }}
            >
              {isPassDailyQuestionaire
                ? 'ประเมินความเสี่ยงอีกครั้ง'
                : 'ประเมินความเสี่ยงวันนี้'}
            </MorChanaPrimaryButton>
          </div>
        </div>
        {!disableStayHome ? (
          <div className="bg-white flex mt-8 py-6">
            <div className="flex-1 text-center w-1/2 px-4">
              <h2 className="text-base font-medium" style={{ color: '#43484' }}>
                อยู่บ้านต่อเนื่อง
              </h2>
              <h1
                className="text-5xl font-medium m-0"
                style={{ color: '#16191D' }}
              >
                {stayHomeCombo}
              </h1>
              <div className="text-base">วัน</div>
            </div>
            <Divider type="vertical" />
            <div className="flex-1 px-4 w-1/2">
              <div className="flex justify-between mb-2">
                <div
                  className="text-base font-medium"
                  style={{ color: '#16191D' }}
                >
                  อยู่บ้าน
                </div>
                <div>สัปดาห์นี้</div>
              </div>
              <Row>{stayhomeIcons}</Row>
              <div className="text-xs">
                <span className="font-medium">
                  {_.countBy(stayHomeWeekArray)[true]}
                </span>{' '}
                จาก 7 วัน
              </div>
            </div>
          </div>
        ) : null}

        <div className="mt-5 bg-white p-4" style={{ fontSize: 12 }}>
          Cleverse does not collect the result of the survey, this, it just the
          displayed the data from MorChana
        </div>
        <div className="mt-2  mb-10 ">
          <HistoryList limit={3} />
        </div>
      </Content>
      <QRCodeSheet
        qrCodeUrl={qrUrl}
        visible={showQRCode}
        assessedDate={assessedDate}
        onClose={() => {
          setShowQRCode(false)
        }}
      />
      {/* {!isPassDailyQuestionaire && <DailyQuestionairePortal />} */}
    </Container>
  )
}
