import { RightOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { useWorkpointData } from 'hooks/workpoint'
import React from 'react'

import { ReactComponent as UpIcon } from './up.svg'

const defaultSummary = {
  confirmed: 0,
  recovered: 0,
  deaths: 0,
}

const Container = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  flex-flow: row wrap;
  background-color: white;
  margin-bottom: 8px;
`

const Confirmed = ({ value, diff }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: 12,
        borderRight: '1px solid rgba(15, 16, 75, 0.06)',
      }}
    >
      <div style={{ color: '#0F104B', fontSize: 12 }}>ผู้ติดเชื้อในไทย</div>
      <div
        style={{
          color: '#B0170F',
          fontSize: window.innerWidth >= 360 ? 40 : 24,
          fontWeight: 600,
          lineHeight: '1.1em',
        }}
      >
        {value.toLocaleString()}
      </div>
      <div
        style={{
          color: '#0F104B',
          fontSize: 12,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <UpIcon style={{ marginRight: 4 }} /> เพิ่ม {diff} คน
      </div>
    </div>
  )
}

const StatContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
`

const Stat = ({ value, title }) => {
  return (
    <StatContainer>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
        }}
      >
        <div style={{ color: '#0F104B', fontSize: 12 }}>{title}</div>
        <div style={{ color: '#0F104B', fontSize: 24, fontWeight: 600 }}>
          {value.toLocaleString()}
        </div>
      </div>
    </StatContainer>
  )
}

export const WorkpointStats = () => {
  const [{ worldReport, constants }, wpLoading] = useWorkpointData()
  if (!worldReport || !constants) {
    return null
  }
  const thailandReport =
    worldReport.statistics.find((country) => country.alpha2 === 'TH') ??
    defaultSummary

  const addedNote = (constants['โน๊ตผู้ติดเชื้อ'] || '').split(' ')[1]
  const treat = Number(constants['กำลังรักษา']) || 0

  return (
    <Container>
      <Confirmed value={thailandReport.confirmed} diff={addedNote} />
      <div
        style={{
          alignSelf: 'stretch',
          display: 'flex',
          justifyContent: 'space-around',
          borderRight: '1px solid rgba(15, 16, 75, 0.06)',
          flex: 1,
          padding: 8,
        }}
      >
        <Stat title="กำลังรักษา" value={treat} />
        <Stat title="หายแล้ว" value={thailandReport.recovered} />
        <Stat title="เสียชีวิต" value={thailandReport.deaths} />
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 8,
        }}
      >
        <RightOutlined style={{ color: '#0F104B' }} />
      </div>
    </Container>
  )
}
