import React from 'react'
import styled from '@emotion/styled'

const Container = styled.div`
  height: 48px;
  .fixed-content {
    height: 48px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);    
    padding-bottom: env(safe-area-inset-bottom);
    box-sizing: content-box;
  }
  padding-bottom: env(safe-area-inset-bottom);
  box-sizing: content-box;
  span {
    color: white;
    font-size: 12px;
  }
`

export const RedirectBanner = (props: any) => {
  return (
    <Container {...props}>
      <div className="fixed-content">
        <span>
          You are bring redirected to{' '}
          <span style={{ fontWeight: 600 }}>MorChana</span>
        </span>
      </div>
    </Container>
  )
}
