import { mapValues } from 'lodash'
import moment from 'moment'
import { useMemo } from 'react'

import { useQuiz } from '../state/userInfo.state'
import defaultRiskAlgo from './risk-algo.json'

const profileFields = [
  'fever',
  'one_uri_symp',
  'travel_risk_country',
  'covid19_contact',
  'close_risk_country',
  'int_contact',
  'med_prof',
  'close_con',
]

export const riskEval = (data, { riskAlgo = defaultRiskAlgo } = {}) => {
  console.log('data', data)
  const { risk_level, gen_action, spec_action } = riskAlgo.records.find(
    (record) => {
      const sum = profileFields
        .map((key) => {
          return data[key] === record[key] ? 1 : 0
        })
        .reduce((sum, number) => {
          return sum + number
        }, 0)
      return sum === profileFields.length
    },
  )
  return { risk_level, gen_action, spec_action }
}

interface Questionaire {
  covid19_contact: (
    | 'close_con'
    | 'close_risk_country'
    | 'covid19_contact'
    | 'none'
  )[]
  int_contact: boolean
  one_uri_symp: (
    | 'none'
    | 'fever'
    | 'one_uri_symp_1'
    | 'one_uri_symp_2'
    | 'one_uri_symp_3'
    | 'one_uri_symp_4'
  )[]
  travel_risk_country: boolean
}

const RISK_TO_CODE = {
  1: 'green',
  2: 'yellow',
  3: 'orange',
  4: 'red',
}

export const calculateCodeFromQuestionaire = (questionaire: Questionaire) => {
  const o = mapValues(
    {
      fever: questionaire.one_uri_symp.includes('fever'),
      one_uri_symp:
        questionaire.one_uri_symp.includes('one_uri_symp_1') ||
        questionaire.one_uri_symp.includes('one_uri_symp_2') ||
        questionaire.one_uri_symp.includes('one_uri_symp_3') ||
        questionaire.one_uri_symp.includes('one_uri_symp_4'),
      travel_risk_country: questionaire.travel_risk_country,
      covid19_contact: questionaire.covid19_contact.includes('covid19_contact'),
      close_risk_country: questionaire.covid19_contact.includes(
        'close_risk_country',
      ),
      int_contact: questionaire.int_contact,
      close_con: questionaire.covid19_contact.includes('close_risk_country'),
      med_prof: false,
    },
    (v) => (v ? 1 : 0),
  )

  return riskEval(o)

  // return RISK_TO_CODE[risk_level]
}

export const usePassQuestionaire = () => {
  const { latestQuiz } = useQuiz()
  return useMemo(() => {
    if (!latestQuiz) return false
    return moment(latestQuiz.timestamp).isSame(moment(), 'day')
  }, [])
}

export const COVID19_CONTACT_LABEL = {
  close_con: 'ประกอบอาชีพใกล้ชิดกับชาวต่างชาติ',
  travel_risk_country: 'เดินทางไปในพื้นที่เสี่ยง หรือมีการระบาดของโรค',
  int_contact: 'ใกล้ชิดกับบุคคลที่มีความเสี่ยง',
  fever: 'มีไข้สูง 37.5 องศา',
  none: 'ไม่มีพฤติกรรมเสี่ยง',
}
export const ONE_URI_SYMP_LABEL = {
  none: 'ไม่มีอาการป่วยแล้ว',
  fever_n_respi: 'มีทั้ง ไข้ และ อาการระบบทางเดินหายใจ',
  fever: 'มีไข้เกิน 37.5 องศา',
  respi: 'มีอาการระบบทางเดินหายใจ',
  one_uri_symp_1: 'ไอ',
  one_uri_symp_2: 'เจ็บคอ',
}
