import { useEffect } from 'react'

import { flexWpStats } from '../flexMessage/flexWpStats'
import { shareLINEFlexMessages } from './shareLINEFlexMessages'

/*
test by posting these lines in workpoint iframe console
```
args={confirmed:2473, confirmedIncrease: 50, treating: 1427, recovered: 1013, deaths:33}
  window.parent.postMessage(
    { func: 'wpShareStats', args},
    '*'
  )
```
*/

const wpShareStats = (params) => {
  console.log('received', params)
  shareLINEFlexMessages(flexWpStats(params))
}

const fn = {
  wpShareStats,
}

function receiver(e) {
  if (e.origin === 'https://covid19.workpointnews.com') {
    const { data } = e
    const { func, args } = data
    if (fn[func]) {
      fn[func](args)
    }
  }
}
export const PostMessage = () => {
  useEffect(() => {
    window.addEventListener('message', receiver, false)
    return () => {
      window.removeEventListener('message', receiver, false)
    }
  }, [])
  return null
}
