import React from 'react'
import { COLORS } from '../../styles'
import {
  MorChanaSecondaryButton,
  MorChanaLinkButton,
} from '../CovidScreener/SubmitButton'
import { useLiffProfile } from '../../liff'
import styled from '@emotion/styled'
import { useHistory } from 'react-router-dom'
import { useNotiFrequencySetting } from '../../utils/quiz'

const StyledContainer = styled.div`
  background: url('images/2doctor.png') ${COLORS.MORCHANA_BLUE};
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: contain;
`
export const DailyNotiPermission = () => {
  useLiffProfile()
  const history = useHistory()
  const { setNotificationFrequencySetting } = useNotiFrequencySetting()
  return (
    <StyledContainer className="h-screen flex flex-col items-center text-white text-center p-10 pt-12">
      <img className="max-w-md" src="images/noti-bell.png" alt="noti" />
      <h1 className="text-white leading-tight">
        แจ้งเตือน
        <br />
        ประเมินรายวัน
      </h1>
      <p className="pb-8">
        ให้หมอแจ้งเตือนคุณเพื่อประเมินความเสี่ยงทุกวัน
        หากความเสี่ยงเปลี่ยนแปลงหมอจะได้แนะนำได้ทันที
      </p>
      <MorChanaSecondaryButton
        onClick={() => {          
          setNotificationFrequencySetting('1d')
          history.push('/profile')
        }}
      >
        เปิดแจ้งเตือน
      </MorChanaSecondaryButton>
      <MorChanaLinkButton
        onClick={() => {
          history.push('/profile')
        }}
      >
        ไม่ใช่ตอนนี้
      </MorChanaLinkButton>
    </StyledContainer>
  )
}
