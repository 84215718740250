import { useEffect, useState } from 'react'
import { tracker } from 'utils/tracker'

export const REACT_APP_LIFF_ID: string = process.env.REACT_APP_LIFF_ID || ''
console.log('REACT_APP_LIFF_ID', REACT_APP_LIFF_ID)

let _isInit_ = false
let initPromise
export const initLiff = () => {
  if (_isInit_) {
    return initPromise
  }
  _isInit_ = true
  console.log('init')
  const init = async () => {
    await liff.init({
      liffId: REACT_APP_LIFF_ID || process.env.REACT_APP_LIFF_ID,
    })
    console.log('init complete')
    
  }
  initPromise = init()
  return initPromise
}
export const useInitLiff = () => {
  const [isInit, setIsInit] = useState(_isInit_)
  const [error, setError] = useState(null)
  useEffect(() => {
    if (_isInit_) {
      return
    }
    initLiff()
      .then(() => {
        setIsInit(true)
      })
      .catch((err) => {
        setError(err)
      })
  }, [])
  return [isInit, error]
}

let isIdentified = false
let _profile_: LINE.Profile | null = null
export const useLiffProfile = (): [LINE.Profile | null, any] => {
  const [profile, setProfile] = useState(_profile_)
  const [error, setError] = useState(null)
  useEffect(() => {
    ;(async () => {
      try {
        if (!_isInit_) {
          await initLiff()
        }
        if (!liff.isLoggedIn()) {
          liff.login()
          return
        }
        const p = await liff.getProfile()
        if (!isIdentified) {
          tracker.setUserId(p.userId)
          isIdentified = true
        }
        _profile_ = p
        setProfile(_profile_)
      } catch (err) {
        setError(err)
      }
    })()
  }, [])
  return [profile, error]
}
