import { useMutation, useQuery } from '@apollo/react-hooks'
import { ApolloClient, InMemoryCache, gql } from 'apollo-boost'
import axios from 'axios'
import _ from 'lodash'
// const screenerResultDefault = null
// {
//   __typename: 'ScreenerResult',
//   risk_level: 3,
//   gen_action: 'ล้างมือ สวมหน้ากาก หลีกเลี่ยงที่แออัด',
//   spec_action: 'ให้เฝ้าระวังอาการตนเอง ถ้ามีอาการไข้ ร่วมกับ อาการระบบทางเดินหายใจ (มีทั้ง 2 อาการ) ให้ติดต่อสถานพยาบาลทันที'
// }
import moment from 'moment-timezone'
import { useCallback, useMemo } from 'react'

import { calQuiz } from '../utils/quiz'

export const UserInfoQuery = gql`
  query {
    userInfo @client {
      id
      formData
      screenerResult {
        __typename
        risk_level
        gen_action
        spec_action
      }
      qrCodeURL
      consentAgreed
      quizArray {
        __typename
        id
        timestamp
        data
      }
      __typename
    }
  }
`
const ConsentAgreedQuery = gql`
  query {
    userInfo @client {
      consentAgreed
    }
  }
`

const QuizArrayQuery = gql`
  query {
    userInfo @client {
      quizArray {
        timestamp
        id
        data
      }
    }
  }
`

const ensureUserInfo = (userInfo: UserInfo): UserInfo => {
  if (!userInfo.qrCodeURL) {
    userInfo.qrCodeURL = null
  }

  if (!userInfo.screenerResult?.risk_level) {
    userInfo.screenerResult = null
  }
  if (userInfo.consentAgreed === undefined) {
    userInfo.consentAgreed = false
  }
  if (userInfo.quizArray === undefined) {
    userInfo.quizArray = []
  }
  return userInfo
}

export const apolloState = {
  async migrate(client: ApolloClient<InMemoryCache>) {
    return new Promise((resolve, reject) => {
      const sub = client
        .watchQuery<{
          userInfo: UserInfo
        }>({
          query: UserInfoQuery,
          returnPartialData: true, // returnPartialData not work on client.query
        })
        .subscribe(({ data: { userInfo }, loading, errors }) => {
          if (errors) {
            reject(errors)
          }
          if (loading) {
            return
          }
          if (userInfo) {
            client.writeQuery({
              query: UserInfoQuery,
              data: {
                userInfo: ensureUserInfo(userInfo),
              },
            })
          }
          sub.unsubscribe()
          resolve()
        })
    })
  },
  initialState: {
    userInfo: {
      __typename: 'UserInfo',
      id: 1,
      formData: JSON.stringify({}),
      screenerResult: null,
      consentAgreed: false,
      quizArray: [],
    },
  },
  Mutation: {
    setConsentAgreed: async (
      _,
      { consentAgreed }: { consentAgreed: Boolean },
      { cache }: { cache: InMemoryCache },
    ) => {
      const { userInfo } = cache.readQuery<{ userInfo: UserInfo }>({
        query: UserInfoQuery,
      })
      const newUserInfo = {
        ...userInfo,
        consentAgreed,
      }
      cache.writeQuery({
        query: UserInfoQuery,
        data: {
          userInfo: newUserInfo,
        },
      })
    },
    syncUserData: async (
      _,
      {
        formData,
        screenerResult,
      }: {
        formData?: Partial<UserData>
        screenerResult?: Partial<ScreenerResult>
      },
      { cache }: { cache: InMemoryCache },
    ) => {
      const { userInfo } = cache.readQuery<{ userInfo: UserInfo }>({
        query: UserInfoQuery,
      })
      const savedUserInfo = ensureUserInfo({
        ...userInfo,
        formData: formData
          ? JSON.stringify({
              timestamp: Date.now(),
              ...JSON.parse(userInfo?.formData || '{}'),
              ...formData,
            })
          : userInfo?.formData,
        // @ts-ignore
        screenerResult: screenerResult
          ? {
              __typename: 'ScreenerResult',
              ...(userInfo.screenerResult || {}),
              ...screenerResult,
            }
          : userInfo.screenerResult,
      })
      cache.writeQuery({
        query: UserInfoQuery,
        data: {
          userInfo: savedUserInfo,
        },
      })
    },
    addQuiz: (_, { quiz }: { quiz: any }, { cache }) => {
      const query = QuizArrayQuery
      const previous = cache.readQuery({ query })
      const newData = {
        __typename: 'Quiz',
        id: `${previous.userInfo.quizArray.length}`,
        timestamp: moment().format(),
        data: quiz,
      }
      const quizArrayData = [...previous.userInfo.quizArray, newData]

      cache.writeQuery({
        query: QuizArrayQuery,
        data: {
          userInfo: {
            __typename: 'UserInfo',
            id: 1,
            quizArray: quizArrayData,
          },
        },
      })
      return null
    },
  },
}

export const useUserInfo = (): [UserInfo | null, Boolean, any] => {
  const { data, loading, error } = useQuery<{ userInfo: RawUserInfo }>(
    UserInfoQuery,
  )
  const userInfo = useMemo(() => {
    let userInfo
    if (data?.userInfo) {
      userInfo = {
        ...data.userInfo,
        formData: JSON.parse(data.userInfo?.formData || '{}'),
      }
      if (!userInfo.screenerResult) {
        userInfo.screenerResult = null
      }
    }
    return userInfo
  }, [data])
  return [userInfo, loading, error]
}

export const useSyncUserData = () => {
  const [mutate] = useMutation(UserInfoMutation.syncUserData)
  return useCallback(
    (formData, screenerResult) => {
      return mutate({
        variables: { formData, screenerResult },
      })
    },
    [mutate],
  )
}

export const useConsentAgreed = () => {
  const { data } = useQuery<{ userInfo: RawUserInfo }>(ConsentAgreedQuery)
  const [mutate] = useMutation(UserInfoMutation.setConsentAgreed)
  const { consentAgreed } = data?.userInfo
  const setConsentAgreed = useCallback(
    (consentAgreed: boolean) => {
      return mutate({
        variables: { consentAgreed },
      })
    },
    [mutate],
  )
  return { consentAgreed, setConsentAgreed }
}

export const useQuiz = () => {
  const { data } = useQuery(QuizArrayQuery)

  const [mutate] = useMutation(UserInfoMutation.addQuiz)
  const saveQuiz = useCallback(
    (quiz) => {
      return mutate({
        variables: { quiz },
      })
    },
    [mutate],
  )
  const calQuizResult = useMemo(
    () => calQuiz(data ? data.userInfo.quizArray : null),
    [data],
  )
  return { quizArray: data.userInfo.quizArray, saveQuiz, ...calQuizResult }
}

export const UserInfoMutation = {
  syncUserData: gql`
    mutation syncUserData(
      $formData: UserInfo!
      $screenerResult: ScreenerResult
    ) {
      syncUserData(formData: $formData, screenerResult: $screenerResult) @client
    }
  `,
  setConsentAgreed: gql`
    mutation setConsentAgreed($consentAgreed: Boolean!) {
      setConsentAgreed(consentAgreed: $consentAgreed) @client
    }
  `,
  addQuiz: gql`
    mutation addQuiz($quiz: Quiz) {
      addQuiz(quiz: $quiz) @client
    }
  `,
}

const SQUID_API_URL = 'https://api.squid.id'
const DEFAULT_APPLICATION_ID = 'covid-db'
export const UserInfoUtils = {
  sendUserLocation: (userId, location) => {
    return axios.post(
      `${SQUID_API_URL}/location`,
      JSON.stringify({ location }),
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Squid-Apps': `${DEFAULT_APPLICATION_ID}.${userId}`,
        },
      },
    )
  },
  sendQuestionareToSquid: (userId, formData) => {
    return axios.post(
      `${SQUID_API_URL}/userdata`,
      JSON.stringify({ userdata: formData }),
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Squid-Apps': `${DEFAULT_APPLICATION_ID}.${userId}`,
        },
      },
    )
  },
  formatUserData: (userData: { [name: string]: any }) => {
    return {
      ..._.mapValues(userData, (d) => {
        if (d === 'true') {
          return true
        } else if (d === 'false') {
          return false
        }
        return d
      }),
      version: '1',
    }
  },
}
