import { Col, List, Row } from 'antd'
import _ from 'lodash'
import moment from 'moment-timezone'
import React, { useMemo } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { useQuiz } from '../../state/userInfo.state'
import { COLORS } from '../../styles'
import { PADDING_HORIZ, StyledListItem, StyledQRCode } from './common'
import { screenerMeta } from '../../utils/screener-meta'

const ListItem = ({ item, onClick }) => {
  const risk_level = item.data.risk_level
  const meta = screenerMeta[risk_level]
  return (
    <StyledListItem onClick={onClick} arrow={'horizontal'}>
      <Row align="middle" gutter={16}>
        <Col>
          <StyledQRCode color={meta.color} />
        </Col>
        <Col>
          <div
            className="font-normal text-sm"
            style={{ color: COLORS.BLUE_DIM }}
          >
            {moment(item.timestamp)
              .add(543, 'year')
              .format('D MMMM YYYY - HH:mm')}
          </div>{' '}
          <div className="font-light text-sm" style={{ color: COLORS.GRAY_2 }}>
            {meta.label}
          </div>
        </Col>
      </Row>
    </StyledListItem>
  )
}
export const HistoryList = ({ limit }: { limit?: number }) => {
  /* [todo]: remove this */
  // const disabledClick = true
  const history = useHistory()
  const { quizArray } = useQuiz()
  const data = useMemo(() => _.reverse(quizArray.slice()), [quizArray])

  if (!data.length) return null
  return (
    <div>
      <List
        header={
          <div
            className="flex justify-between"
            style={{
              paddingLeft: PADDING_HORIZ,
              paddingRight: PADDING_HORIZ,
            }}
          >
            <div className="font-medium text-black">ประวัติความเสี่ยง</div>
            {limit && (
              <Link to="/profile/quiz/history">
                <img
                  src="https://pantruck.com/icons8/material-sharp/52/576675/settings.png"
                  width={24}
                />
              </Link>
            )}
          </div>
        }
        dataSource={limit ? data.slice(0, limit) : data}
        renderItem={(item: Quiz) => (
          <ListItem
            item={item}
            // disabledClick={disabledClick}
            onClick={() => {
              // if (disabledClick) {
              //   return
              // }
              history.push(`/profile/quiz/result/${item.id}`)
            }}
          />
        )}
      />
      {limit && data.length > limit && (
        <StyledListItem>
          <div className="underline text-sm text-center">
            <Link to="/profile/quiz/history">ดูทั้งหมด</Link>
          </div>
        </StyledListItem>
      )}
    </div>
  )
}
