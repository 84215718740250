import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { COLORS } from '../../styles'

const antIcon = (
  <LoadingOutlined style={{ fontSize: 80, color: 'white' }} spin />
)

export const MorchanaRedirect = ({ to = null, text, delay = 500 }) => {
  const history = useHistory()
  useEffect(() => {
    if (to) {
      setTimeout(() => {
        history.replace(to)
      }, delay)
    }
  }, [])
  return (
    <div
      className="w-full h-screen flex flex-col justify-center items-center text-center"
      style={{ background: COLORS.MORCHANA_BLUE }}
    >
      <Spin indicator={antIcon} />
      <div className="text-xl text-white mt-8">{text}</div>
    </div>
  )
}
