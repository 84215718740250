import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { History } from './History'
import { PrevResult } from './PrevResult'

export const DailyQuiz = () => {
  return (
    <Switch>
      <Route path="/profile/quiz/history">
        <History />
      </Route>
      <Route path="/profile/quiz/result/:quizId">
        <PrevResult />
      </Route>
    </Switch>
  )
}
