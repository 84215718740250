import styled from '@emotion/styled'
import React from 'react'
import { NavLink } from 'react-router-dom'

import { ReactComponent as Hospital } from './hospital.svg'
import { ReactComponent as Info } from './info.svg'
import { ReactComponent as Knowledge } from './knowledge.svg'
import { ReactComponent as Profile } from './profile.svg'
import { ReactComponent as Stats } from './stats.svg'
import { ReactComponent as InfoHub } from './infohub.svg'

const TAB_BAR_HEIGHT = 50
const TabBarContainer = styled.div`
  height: 100vh;
  background-color: #fafafc;
  @media only screen and (min-width: 768px) {
    border: 1px solid #ccc;
    border-top: 0;
    border-bottom: 0;
  }
`
const TabBarContent = styled.div`
  height: 100vh;
  padding-bottom: ${TAB_BAR_HEIGHT}px;
  @supports (padding-bottom: env(safe-area-inset-bottom)) {
    padding-bottom: calc(env(safe-area-inset-bottom) + ${TAB_BAR_HEIGHT}px);
  }
`

const TabBarItem = styled.div`
  height: ${TAB_BAR_HEIGHT}px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: white;
  /* handle iphoneX  */
  padding-bottom: env(safe-area-inset-bottom);
  box-sizing: content-box;
  box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.25);
`

const TAB_BAR_OPTIONS = [
  {
    Icon: Knowledge,
    path: '/workpoint/knowledge',
  },
  {
    Icon: Stats,
    path: '/workpoint/stats',
  },
  {
    Icon: (props) => <InfoHub width={55} height={55} />,
    path: '/',
    exact: true,
    center: true,
  },
  {
    Icon: Hospital,
    path: '/workpoint/hospitals',
  },

  {
    Icon: Profile,
    path: '/profile',
  },
]

const StyledNavLink = styled(NavLink)`
  flex: 1;
  fill: #a6a6b0;
  &.active {
    fill: #0f104b;
  }
  width: 50px;
`

const CenterIconBackground = styled.div`
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 100%;
  background: white;
`

const CenterIconContainer = styled.div`
  position: relative;
  height: ${TAB_BAR_HEIGHT}px;
`

export const TabBar = ({ children }) => {
  return (
    <TabBarContainer>
      <TabBarContent key={window.location.pathname}>{children}</TabBarContent>
      <TabBarItem>
        {TAB_BAR_OPTIONS.map((options, index) => (
          <StyledNavLink
            to={options.path}
            exact={options.exact}
            activeClassName="active"
            key={index}
            replace
          >
            {options.center ? (
              <CenterIconContainer>
                <CenterIconBackground>
                  <options.Icon />
                </CenterIconBackground>
              </CenterIconContainer>
            ) : (
              <options.Icon />
            )}
          </StyledNavLink>
        ))}
      </TabBarItem>
    </TabBarContainer>
  )
}
