import useAxios from 'axios-hooks'
import './workpoint.d'

const WORKPOINT_API_PATH = 'https://covid19-cdn.workpointnews.com/api'

type WorkpointResult = [{ worldReport: WorldReport, constants: Record<string, string> }, boolean]

export const useWorkpointData = (): WorkpointResult => {
  const [{ data: worldReport, loading: worldLoading }] = useAxios(
    `${WORKPOINT_API_PATH}/world.json`
  )
  const [{ data: constants, loading: constantsLoading }] = useAxios(
    `${WORKPOINT_API_PATH}/constants.json`
  )
  return [{ worldReport, constants }, worldLoading || constantsLoading]
}