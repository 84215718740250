import React, { Fragment, useState } from 'react'
import { LoadingScreen } from 'components/Loading'
import styled from '@emotion/styled'

const LoaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export const IFrameWithLoading = ({
  src,
  LoadingComponent = LoadingScreen,
  delay = 0,
  ...props
}: {
  src: string
  delay?: number
  LoadingComponent?: any
  [name: string]: any
}) => {
  const [loading, setLoading] = useState(true)
  return (
    <Fragment>
      {loading ? (
        <LoaderWrapper>
          <LoadingComponent />
        </LoaderWrapper>
      ) : null}
      <iframe
        {...props}
        src={src}
        width="100%"
        height="100%"
        onLoad={() => {
          setTimeout(() => {
            setLoading(false)
          }, delay)
        }}
        frameBorder="0"
      />
    </Fragment>
  )
}
