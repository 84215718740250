import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuiz, useSyncUserData } from '../../state/userInfo.state'
import { MorchanaRedirect } from '../Morchana/MorChanaRedirect'
import { RedirectBanner } from './RedirectBanner'
import { useMorchanaToken } from 'utils/morchana'
import { IFrameWithLoading } from 'components/IframeWithLoading'

const risk_level_meta = [
  {
    risk_level: 1,
    gen_action: 'ล้างมือ สวมหน้ากาก หลีกเลี่ยงที่แออัด',
    spec_action: ' ',
  },
  {
    risk_level: 2,
    gen_action: 'ล้างมือ สวมหน้ากาก หลีกเลี่ยงที่แออัด',
    spec_action: 'อาจเป็นโรคอื่น ถ้า 2 วัน อาการไม่ดีขึ้นให้ไปพบแพทย์',
  },
  {
    risk_level: 3,
    gen_action: 'ล้างมือ สวมหน้ากาก หลีกเลี่ยงที่แออัด',
    spec_action:
      'เนื่องจากท่านมีประวัติเดินทางจากพื้นที่เสี่ยง ให้กักตัว 14 วัน พร้อมเฝ้าระวังอาการ ถ้ามีอาการไข้ ร่วมกับ อาการระบบทางเดินหายใจ ให้ติดต่อสถานพยาบาลทันที',
  },
  {
    risk_level: 4,
    gen_action: 'ล้างมือ สวมหน้ากาก หลีกเลี่ยงที่แออัด',
    spec_action: 'ให้ติดต่อสถานพยาบาลทันที',
  },
]

export const CovidScreener = () => {
  const syncUserData = useSyncUserData()
  const { saveQuiz } = useQuiz()
  const [token, tokenLoading] = useMorchanaToken()
  const [loading, setLoading] = useState(true)
  const history = useHistory()
  const MORCHANA_URL = process.env.REACT_APP_MORCHANA_URL

  useEffect(() => {
    const onMessage = (e) => {
      const fn = {
        redirect: (args) => {
          console.log('args.quizData', args)
          saveQuiz(args)

          /* [todo]: remove this, after fetch qrdata from thaialert */
          syncUserData(
            {},
            risk_level_meta.find((m) => m.risk_level === args.risk_level),
          )
          history.push('/morchana/redirect-back')
        },
      }
      if (e.origin === MORCHANA_URL) {
        const { data } = e
        const { func, args } = data
        if (fn[func]) {
          fn[func](args)
        }
      }
    }
    window.addEventListener('message', onMessage, false)
    return () => window.removeEventListener('message', onMessage)
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100vw',
        height: '100vh',
      }}
    >
      <IFrameWithLoading
        style={{ width: '100%', flex: 1 }}
        src={`${MORCHANA_URL}/q/home?token=${token}`}
        allow="geolocation"
        delay={1000}
        LoadingComponent={() => (
          <MorchanaRedirect text="ระบบกำลังนำคุณไปยังหมอชนะ..." />
        )}
      />
      <RedirectBanner />
    </div>
  )
}
