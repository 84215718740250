import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Result } from './Result'
import { decodeJWT } from './utils/jwt'
import WebScanner from './WebScanner'
import { useHistory } from 'react-router'
import { QRResult } from 'qr'
import { useMorchanaToken, scan } from 'utils/morchana'

const CloseButton = ({ style = {} }) => {
  const history = useHistory()
  return (
    <button
      style={{
        backgroundColor: '#3b4fe4',
        border: '1px solid #3b4fe4',
        color: 'white',
        padding: '0.5rem 6rem',
      }}
      className="px-24 rounded-full font-semibold mx-auto"
      onClick={() => history.replace('/')}
    >
      กลับ
    </button>
  )
}

const LiffScanner = () => {
  const [result, setResult] = useState<any>(null)
  const [error, setError] = useState<string>('')

  const onRescan = useCallback(() => setResult(null), [setResult])

  useEffect(() => {
    if (!result) {
      liff.scanCode().then((data) => {
        const { value } = data
        const result = decodeJWT(value)
        if (!result?._) {
          setError('ข้อมูลไม่ถูกต้อง')
          setTimeout(() => {
            setError('')
          }, 4000)
          return
        }
        const qrResult = new QRResult(result)
        setResult({
          anonymousId: qrResult.annonymousId,
          iat: qrResult.iat,
          color: qrResult.code,
        })
      })
    }
  }, [result])

  return (
    <div>
      {error !== '' && (
        <div className="w-full h-screen text-center flex flex-col justify-center items-center overflow-auto">
          <div className={`p-4 text-xl`}>{error}</div>
          <button
            className="py-4 px-24 rounded-full font-semibold mx-auto"
            style={{ backgroundColor: '#4a4b53' }}
            onClick={onRescan}
          >
            สแกนใหม่
          </button>
          <CloseButton />
        </div>
      )}
      {result && (
        <Result result={result} onRescan={onRescan} BackButton={CloseButton} />
      )}
    </div>
  )
}

export const useOpenScanner = () => {
  const history = useHistory()
  const [token] = useMorchanaToken()
  return () => {
    if (!liff.scanCode) {
      liff.openWindow({
        url: `/scan?morchanaToken=${token}&backURL=${encodeURIComponent(
          'line://',
        )}`,
        external: true,
      })
      return
    }
    history.replace('/scan')
  }
}

export const Scanner = () => {
  if (liff.isInClient() && liff.scanCode) return <LiffScanner />
  return <WebScanner />
}
