import { css } from '@emotion/core'
import QrReader from 'react-qr-reader'
import React, { useMemo, useState, useEffect } from 'react'
import { REACT_APP_LIFF_ID } from 'liff'
import { decodeJWT } from './utils/jwt'
import { Result } from './Result'
import { QRResult } from 'qr'
import { useMorchanaToken, scan } from 'utils/morchana'

const BackToLINEButton = ({ backURL, style = {} }) => {
  return (
    <a
      href={backURL || `https://liff.line.me/${REACT_APP_LIFF_ID}`}
      style={style}
    >
      <button
        style={{
          backgroundColor: '#00b833',
          color: 'white',
          padding: '0.5rem 3rem',
        }}
        className="px-24 rounded-full font-semibold mx-auto"
      >
        <img
          style={{
            display: 'inline-block',
            marginRight: 5,
            marginTop: -2,
          }}
          width={20}
          src={require('./assets/icons8-line.svg')}
        />
        กลับไปที่ไลน์
      </button>
    </a>
  )
}

const JsScanner = () => {
  const query = new URLSearchParams(window.location.search)
  const backURL = query.get('backURL')
  const [result, setResult] = useState(null)
  const [error, setError] = useState<any>({})
  return (
    <div
      className="h-screen overflow-auto"
      style={{
        color: '#f1f5fa',
        backgroundColor: '#303342',
      }}
    >
      <div className="container mx-auto relative p-4">
        <div className="mb-5">
          <h1
            className="font-semibold text-4xl mb-1"
            style={{
              color: '#f1f5fa',
            }}
          >
            สแกน QR CODE
          </h1>
          <p className="font-light text-sm" style={{ color: '#F1F5FA' }}>
            สแกน QR Code เพื่อดูความเสี่ยง COVID-19
          </p>
        </div>
        {!result && (
          <QrReader
            delay={300}
            style={{ width: '100%' }}
            onScan={(data) => {
              if (data) {
                const result = decodeJWT(data)
                if (!result?._) {
                  setError({
                    title: 'ข้อมูลไม่ถูกต้อง',
                  })
                  setTimeout(() => {
                    setError({})
                  }, 4000)
                  return
                }
                const qrResult = new QRResult(result)
                setResult({
                  anonymousId: qrResult.annonymousId,
                  iat: qrResult.iat,
                  color: qrResult.code,
                })
              }
            }}
          />
        )}
        <div style={{ paddingTop: '2rem' }}>
          <BackToLINEButton
            backURL={backURL}
            style={{ display: 'flex', alignItems: 'center' }}
          />
        </div>
        {error?.title && (
          <div
            className={`animated fadeOut text-center p-4 text-xl`}
            style={{
              animationDuration: '200ms',
              animationDelay: '3s',
            }}
          >
            {error?.title}
          </div>
        )}

        <div className={`${result ? 'block' : 'hidden'}`}>
          <Result
            result={result}
            onRescan={() => setResult(null)}
            BackButton={() => <BackToLINEButton backURL={backURL} />}
          />
        </div>
      </div>
    </div>
  )
}

export default JsScanner
