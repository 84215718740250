import React from 'react'
import styled from '@emotion/styled'
import Lottie from 'react-lottie'

const MenuCardDiv = styled.div<{ heightRatio: any }>`
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  margin-bottom: 4px;
  padding: ${props => props.heightRatio * 100}% 0 0 0;
  border-radius: 8px;
  color: #fff;
  background-color: #bbb;
  .text-header {
    color: #eeeeee;
    font-size: 14px;
    font-weight: 400;
  }
  .text-des {
    line-height: 1;
    font-size: 18px;
    font-weight: 800;
    margin-bottom: auto;
  }
  img {
    max-width: 100%;
  }
`
// {textHeader, textDes, logoSponsor, bgImage}
export const MenuCard = ({ lottie, heightRatio = 1 }) => {  
  return (
    <MenuCardDiv heightRatio={heightRatio}>
      <div
        style={{
          display: 'block',
          maxWidth: '100%',
          maxHeight: '100%',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: lottie,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
        />
      </div>
      {/* <div className="text-header">{textHeader}</div>
      <div className="text-des">{textDes}</div>
      <div className="logo">
        {logoSponsor ? <img src={logoSponsor} alt="Sponsor Logo" /> : null}
      </div> */}
    </MenuCardDiv>
  )
}
