import { Picker } from 'antd-mobile'
import 'antd-mobile/lib/list/style/css'
import 'antd-mobile/lib/picker/style/css'
import { default as React } from 'react'
import { COLORS } from '../../styles'
import { useNotiFrequencySetting } from '../../utils/quiz'
import { MorChanaBackButton } from '../CovidScreener/BackHandler'
import { StyledListItem } from './common'
import { HistoryList } from './HistoryList'

const data = [
  { value: null, label: 'ไม่แจ้งเตือน' },
  { value: '1d', label: 'ทุกวัน' },
  { value: '2d', label: 'ทุก 2 วัน' },
  { value: '4d', label: 'ทุก 4 วัน' },
  { value: '7d', label: 'ทุกสัปดาห์' },
]
export const History = () => {
  const {
    frequency,
    setNotificationFrequencySetting,
  } = useNotiFrequencySetting()
  return (
    <div className="h-screen overflow-auto" style={{ background: '#F5F5F9' }}>
      <div className="bg-white px-4 py-2">
        <MorChanaBackButton />
      </div>
      <Picker
        title="เวลา"
        data={data}
        cols={1}
        value={[frequency]}
        extra=" "
        onChange={(fr) => {
          setNotificationFrequencySetting(fr[0])
        }}
        okText="ตกลง"
        dismissText="ยกเลิก"
      >
        <StyledListItem arrow="horizontal">
          <div className="font-medium text-lg text-black">เวลาแจ้งเตือน</div>
          <div className="font-normal text-sm" style={{ color: COLORS.GRAY_4 }}>
            เพื่อประเมินความเสี่ยง
          </div>
        </StyledListItem>
      </Picker>

      <div className="mt-5 bg-white p-4">
        Cleverse does not collect the result of the survey, this, it just the
        displayed the data from MorChana
      </div>
      <div className="mt-2  mb-10 ">
        <HistoryList />
      </div>
    </div>
  )
}
