import { REACT_APP_LIFF_ID } from 'liff'
import moment from 'moment-timezone'

const PUBLIC_URL =
  process.env.REACT_APP_PUBLIC_URL || 'https://covid19.cleverse.com'

export type Params = {
  confirmed: number
  treating: number
  recovered: number
  deaths: number
  confirmedIncrease: number
}

export const flexWpStats = ({
  confirmed,
  treating,
  recovered,
  deaths,
  confirmedIncrease,
}: Params) => {
  const today = moment().format('DD MMMM')
  const date = moment().format('DD-MM-YYYY')
  const graphImgUrl = `https://covid19-cdn.workpointnews.com/image/${date}-thailand_graph.png`
  return {
    type: 'flex',
    altText: `สถานการณ์ Covid-19 (${today})`,
    contents: {
      type: 'bubble',
      header: {
        type: 'box',
        layout: 'horizontal',
        contents: [
          {
            type: 'text',
            text: 'สถานการณ์ COVID-19',
            size: 'sm',
            weight: 'bold',
            flex: 2,
          },
          {
            type: 'text',
            text: today,
            align: 'end',
            size: 'sm',
          },
        ],
        paddingTop: '10px',
        backgroundColor: '#EAEAEA',
        paddingBottom: '10px',
      },
      hero: {
        type: 'box',
        layout: 'vertical',
        contents: [
          {
            type: 'box',
            layout: 'vertical',
            contents: [
              {
                type: 'box',
                layout: 'vertical',
                contents: [
                  {
                    type: 'text',
                    text: 'ผู้ติดเชื้อประเทศไทย',
                    weight: 'bold',
                    color: '#140F4D',
                  },
                  {
                    type: 'text',
                    contents: [
                      {
                        type: 'span',
                        text: `${confirmed.toLocaleString()} `,
                        size: '4xl',
                        weight: 'bold',
                        color: '#D1254E',
                      },
                      {
                        type: 'span',
                        text: `+${confirmedIncrease.toLocaleString()}`,
                        size: 'xl',
                        color: '#111111',
                      },
                    ],
                  },
                ],
                paddingBottom: '15px',
              },
              {
                type: 'box',
                layout: 'horizontal',
                contents: [
                  {
                    type: 'box',
                    layout: 'vertical',
                    contents: [
                      {
                        type: 'text',
                        text: 'กำลังรักษา',
                        size: 'sm',
                      },
                      {
                        type: 'text',
                        text: treating.toLocaleString(),
                        size: 'xl',
                        color: '#75C8A2',
                        weight: 'bold',
                      },
                    ],
                  },
                  {
                    type: 'box',
                    layout: 'vertical',
                    contents: [
                      {
                        type: 'text',
                        text: 'หายแล้ว',
                        size: 'sm',
                      },
                      {
                        type: 'text',
                        text: recovered.toLocaleString(),
                        size: 'xl',
                        color: '#3778F0',
                        weight: 'bold',
                      },
                    ],
                  },
                  {
                    type: 'box',
                    layout: 'vertical',
                    contents: [
                      {
                        type: 'text',
                        text: 'เสียชีวิต',
                        size: 'sm',
                      },
                      {
                        type: 'text',
                        text: deaths.toLocaleString(),
                        size: 'xl',
                        color: '#161616',
                        weight: 'bold',
                      },
                    ],
                  },
                ],
                paddingBottom: '15px',
              },
            ],
            paddingStart: '20px',
            paddingEnd: '20px',
          },
          {
            type: 'separator',
          },
          {
            type: 'box',
            layout: 'vertical',
            contents: [
              {
                type: 'text',
                text: 'แนวโน้มผู้ติดเชื้อเทียบกับต่างประเทศ',
                weight: 'bold',
                color: '#140F4D',
              },
              {
                type: 'text',
                contents: [
                  {
                    type: 'span',
                    text: 'นับจากวันที่ผู้ติดเชื้อมากกว่า ',
                  },
                  {
                    type: 'span',
                    text: '100 คน',
                    color: '#B71818',
                    weight: 'bold',
                  },
                ],
                size: 'sm',
              },
            ],
            paddingStart: '20px',
            paddingEnd: '20px',
            paddingTop: '15px',
            paddingBottom: '15px',
          },
          {
            type: 'image',
            url: graphImgUrl,
            aspectMode: 'cover',
            size: 'full',
            aspectRatio: '15:9',
          },
        ],
        paddingTop: '15px',
      },
      body: {
        type: 'box',
        layout: 'vertical',
        contents: [
          {
            type: 'button',
            action: {
              type: 'uri',
              label: 'ดูรายละเอียด',
              uri: `https://liff.line.me/${REACT_APP_LIFF_ID}/workpoint/stats`,
            },
            style: 'primary',
            color: '#0F104B',
            height: 'md',
          },
        ],
        paddingTop: '15px',
      },
      footer: {
        type: 'box',
        layout: 'vertical',
        contents: [
          {
            type: 'box',
            layout: 'horizontal',
            contents: [
              {
                type: 'box',
                layout: 'vertical',
                contents: [
                  {
                    type: 'image',
                    url: `${PUBLIC_URL}/ms-icon-70x70.png`,
                    position: 'absolute',
                    size: 'full',
                    offsetTop: '0px',
                    offsetBottom: '0px',
                    offsetStart: '0px',
                    offsetEnd: '0px',
                  },
                ],
                width: '20px',
                height: '20px',
              },
              {
                type: 'text',
                text: 'COVID-19 Info Hub',
                size: 'xs',
                color: '#999999',
                weight: 'bold',
                style: 'normal',
                wrap: false,
                flex: 10,
                gravity: 'center',
              },
              {
                type: 'box',
                layout: 'horizontal',
                contents: [
                  {
                    type: 'box',
                    layout: 'vertical',
                    spacing: 'sm',
                    contents: [
                      {
                        type: 'spacer',
                        size: 'sm',
                      },
                    ],
                    flex: 0,
                  },
                  {
                    type: 'image',
                    url:
                      'https://obs.line-scdn.net/0hfMog2PK-OWJSHhJH65RGNXlfIwEidiwqPCYyRSlBeVp2LnlgPn12Bn9Ob1d8LCw9ZnAmACQeYVorKCw8Pi10BH9MN1F7JnZxbyhwUCJNNQc',
                    gravity: 'center',
                  },
                ],
                width: '12px',
              },
            ],
            spacing: 'md',
          },
        ],
        action: {
          type: 'uri',
          label: 'action',
          uri: `https://liff.line.me/${REACT_APP_LIFF_ID}/`,
        },
      },
      styles: {
        footer: {
          separator: true,
        },
      },
    },
  }
}
