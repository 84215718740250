import { Col, Row } from 'antd'
import _ from 'lodash'
import moment from 'moment-timezone'
import React from 'react'
import { useParams } from 'react-router-dom'

import { useQuiz } from '../../state/userInfo.state'
import {
  COVID19_CONTACT_LABEL,
  ONE_URI_SYMP_LABEL,
} from '../../utils/questionaire'
import { MorChanaBackButton } from '../CovidScreener/BackHandler'
import { RISK_BAR_COLORS } from '../CovidScreener/const'
import { StyledQRCode } from './common'
import { screenerMeta } from '../../utils/screener-meta'

const Answer = ({ answer }) => (
  <div
    className="rounded py-2 px-4 mb-2 font-medium"
    style={{ background: '#F9FAFB', color: '#84A1BD' }}
  >
    {answer}
  </div>
)
const Item = ({
  question,
  answers,
}: {
  question: string
  answers: string[]
}) => (
  <div className="mb-4">
    <div className="my-2">{question}</div>
    {answers.map((ans) => (
      <Answer answer={ans} />
    ))}
  </div>
)

export const PrevResult = () => {
  const { quizArray } = useQuiz()
  const { quizId } = useParams()
  const quiz = _.find(quizArray, ['id', quizId])
  if (!quiz) return null
  const {
    risk_level,
    int_contact,
    covid19_contact,
    one_uri_symp,
    covid19_contact_or_respi,
  } = quiz.data
  const { timestamp } = quiz
  const meta = screenerMeta[risk_level]
  return (
    <div className="h-screen" style={{ background: '#F5F5F9' }}>
      <div className="bg-white px-4 py-2">
        <MorChanaBackButton />
      </div>
      <div
        className="px-4 py-5 bg-white"
        style={{
          borderBottom: '1px solid #f0f0f0',
        }}
      >
        <Row align="middle" gutter={16}>
          <Col>
            <StyledQRCode size={40} color={meta.color} />
          </Col>
          <Col>
            <div className="font-medium text-lg text-black">
              {moment(timestamp).add(543, 'year').format('D MMMM YYYY - HH:mm')}
            </div>{' '}
            <div>{meta.label}</div>
          </Col>
        </Row>
      </div>
      <div className="px-4 py-5 bg-white">
        <div className="font-medium text-lg text-black">แบบประเมินย้อนหลัง</div>
        {int_contact !== undefined && (
          <Item
            question="1. วันนี้คุณกักตัวอยู่บ้านหรือไม่ ?"
            answers={[int_contact ? 'ใช่' : 'ไม่ใช่']}
          />
        )}
        {covid19_contact_or_respi && (
          <Item
            question="2. วันนี้คุณมีพฤติกรรมเสี่ยงหรืออาการป่วยหรือไม่ ?"
            answers={covid19_contact_or_respi.map(
              (s) => COVID19_CONTACT_LABEL[s],
            )}
          />
        )}
        {covid19_contact && (
          <Item
            question="2. วันนี้คุณมีพฤติกรรมเสี่ยงหรือไม่ ?"
            answers={covid19_contact.map((s) => COVID19_CONTACT_LABEL[s])}
          />
        )}
        {one_uri_symp && (
          <Item
            question="3. อาการป่วยของคุณเป็นอย่างไร ?"
            answers={one_uri_symp.map((s) => ONE_URI_SYMP_LABEL[s])}
          />
        )}
      </div>
    </div>
  )
}
