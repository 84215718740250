import React, { Fragment, useState } from 'react'
import { useRouteMatch } from 'react-router'
import { PostMessage } from '../utils/postMessage'
import { IFrameWithLoading } from 'components/IframeWithLoading'

export const CovidWorkpoint = () => {
  const match = useRouteMatch<{ path: string }>('/workpoint/:path')
  const path = match?.params?.path
  return (
    <>
    <PostMessage />
    <IFrameWithLoading
      key={path}
      src={`https://covid19.workpointnews.com/line/${path}`}
    />
    </>
  )
}
