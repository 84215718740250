import styled from '@emotion/styled'
import { MenuCard } from 'components/MenuCard'
import { useOpenScanner } from 'pages/Scanner/Scanner'
import React from 'react'
import { Link } from 'react-router-dom'
import { usePassQuestionaire } from 'utils/questionaire'
import { ScrollToTopOnMount } from 'utils/ScrollToTopOnMount'
import { DailyQuestionairePortal } from '../../components/DailyQuestionairePortal'
import { useQuiz } from '../../state/userInfo.state'
import { HeroSection } from './HeroSection'
import { WorkpointStats } from './WorkpointStats'

const mapData = require('./map.json')
const scannerData = require('./scanner.json')
const knowledgeData = require('./knowledge.json')
const hospitalData = require('./hospital.json')

const Container = styled.div`
  background: #e8e8ee;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`

const MenuGrid = styled.div`
  margin-top: 8px;
  display: grid;
  grid-gap: 4px;
  grid-template-columns: 1fr 1fr;
`

export const Home = () => {
  const openScanner = useOpenScanner()
  const isPassDailyQuestionaire = usePassQuestionaire()

  const { quizArray, saveQuiz } = useQuiz()
  return (
    <Container>
      <ScrollToTopOnMount />
      {!isPassDailyQuestionaire && <DailyQuestionairePortal />}
      <div style={{ padding: 8 }}>
        <HeroSection />
        <Link replace to="/workpoint/stats">
          <WorkpointStats />
        </Link>
        <MenuGrid>
          <div>
            <Link replace to="/workpoint/stats" className="MENU-1">
              <MenuCard lottie={mapData} heightRatio={1600 / 1000} />
            </Link>
            <Link replace to="/workpoint/knowledge" className="MENU-3">
              <MenuCard lottie={knowledgeData} heightRatio={800 / 1000} />
            </Link>
          </div>
          <div>
            <a onClick={openScanner} className="MENU-2">
              <MenuCard lottie={scannerData} heightRatio={1000 / 1000} />
            </a>
            <Link replace to="/workpoint/hospitals" className="MENU-4">
              <MenuCard lottie={hospitalData} heightRatio={1400 / 1000} />
            </Link>
          </div>
        </MenuGrid>
      </div>
    </Container>
  )
}
