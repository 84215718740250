export const SCREENER_COLOR = {
  BACKGROUND: '#00A0D7',
  PRIMARY: '#216DB8',
  LIGHT_PRIMARY: '#E6F2FA',
  BLACK_TEXT: '#0C2641',
  GRAY_TEXT: '#576675',
  LIGHT_GRAY_TEXT: '#C4CCD4',
  LIGHT_BLUE_BACKGROUND: '#FAFDFF',
}

const COUNTRIES = require('./countries.json')

export { COUNTRIES }
export const RISK_COUNTRY = [
  'จีน',
  'ญี่ปุ่น',
  'สิงคโปร์',
  'เกาหลีใต้',
  'ฮ่องกง',
  'ไต้หวัน',
  'มาเก๊า',
  'เยอรมัน',
  'ฝรั่งเศส',
  'อื่นๆ',
]

export const YESNO_QUESTIONS = {
  symptom1:
    'คุณมีไข้ ตัวร้อน หรือรู้สึกครั่นเนื้อครั่นตัว อย่างใดอย่างหนึ่งหรือไม่',
  symptom2:
    'คุณมีอาการไอ เจ็บคอ มีเสมหะ มีน้ำมูก หายใจลำบาก อย่างใดอย่างหนึ่งหรือไม่',
  travel1:
    'เมื่อ 2 สัปดาห์ที่ผ่านมาคุณได้เดินทางผ่าน ประเทศจีน-ฮ่องกง มาเก๊า เกาหลีใต้ อิหร่าน อิตาลี หรือไม่',
  travel2: 'คุณได้เดินทางไปต่างประเทศในช่วง 2 สัปดาห์ที่ผ่านมาหรือไม่',
  travel2a: 'ประเทศที่คุณเพิ่งเดินทางกลับมาในช่วง 14 วันที่ผ่านมา',
  travel3:
    '2 สัปดาห์ที่ผ่านมาคุณได้เดินทางผ่านประเทศไต้หวัน สิงคโปร์ เยอรมนี ฝรั่งเศส สเปน หรือญี่ปุ่น หรือไม่',
  community1:
    'ในบ้านหรือห้องพัก มีคนเดินทางมาจากพื้นที่เสี่ยงในช่วง 14 วันหรือไม่?\nพื้นที่เสี่ยงได้แก่ ประเทศจีน-ฮ่องกง มาเก๊า เกาหลีใต้ อิหร่าน อิตาลี ไต้หวัน สิงคโปร์ เยอรมนี ฝรั่งเศส สเปน หรือญี่ปุ่น',
  community2: 'คุณประกอบอาชีพที่เจอกับนักท่องเที่ยวชาวต่างชาติเป็นประจำหรือไม่',
  community3:
    'คุณได้สัมผัสหรือทำกิจกรรมร่วมกับผู้ติดเชื้อ(ได้รับการตรวจยืนยันว่าเป็นโรค COVID-19) หรือไม่',
}

export const permissionGroupMapping = {
  demographic: 'ข้อมูลพื้นฐาน',
  location: 'ตำแหน่งปัจจุบันของคุณ',
  id: 'ติดต่อ',
  covid: 'อาการป่วย',
  travel: 'การเดินทาง',
  community: 'โอกาสติดเชื้อ',
}
export const RISK_BAR_COLORS = {
  1: '#56AF3E',
  2: '#F3DF27',
  3: '#FF981E',
  4: '#F85151',
  DEFAULT: '#CDCDCD',
}
