import React from 'react'

import { useQuiz } from '../../state/userInfo.state'
import { MorchanaRedirect } from './MorChanaRedirect'

const NOTI_SETTING_VALUE = 'true'
export const MorchanaRedirectBack = () => {
  const notiSetting = localStorage.getItem('noti-setting')
  const firstTimeBack = notiSetting !== NOTI_SETTING_VALUE
  console.log('noti-setting', notiSetting)

  const text = (
    <p>
      ระบบกำลังนำคุณไปยัง
      <br />
      LINE COVID-19 Info Hub
    </p>
  )
  if (firstTimeBack) {
    setTimeout(() => {
      localStorage.setItem('noti-setting', NOTI_SETTING_VALUE)
    }, 600)

    return <MorchanaRedirect to="/noti-permission" text={text} delay={500} />
  }
  return <MorchanaRedirect to="/profile" text={text} delay={500} />
}
