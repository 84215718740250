import { RightOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { Col, Row } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

const DailyQuestionairePortalContainer = styled(Link)`
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  background: #0f104b;
  color: white;
`

export const DailyQuestionairePortal = () => {
  return (
    <DailyQuestionairePortalContainer to="/screener/daily">
      <Row className="w-full" align="middle">
        <Col className="mr-4">
          <img src="/images/document-icon.png" alt="doc" height={38} />
        </Col>
        <Col flex="1">
          <Row justify="space-between" align="middle">
            <div>
              <div className="font-medium">
                คุณยังไม่ได้ประเมินอาการของวันนี้ !
              </div>
              <div className="font-light">อย่าลืมประเมินความเสี่ยงทุกวัน</div>
            </div>
            <RightOutlined style={{ fontSize: 21 }} />
          </Row>
        </Col>
      </Row>
    </DailyQuestionairePortalContainer>
  )
}
