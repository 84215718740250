import { useEffect } from 'react'
import amplitude from 'amplitude-js'
import { useLocation } from 'react-router'

class Tracker {
  constructor() {
    amplitude.getInstance().init('0257663ae17eff3b19d27bba8556ca4a')
  }
  setUserId(userId) {
    amplitude.getInstance().setUserId(userId)
  }
  setUserProperites(object: { [key: string]: any }) {
    const identify = new amplitude.Identify()
    for (let key in object) {
      identify.set(key, object[key])
    }
    amplitude.getInstance().identify(identify)
  }
  track(eventName: string, props?: any) {
    amplitude.getInstance().logEvent(eventName, props)
    // @ts-ignore
    gtag('event', eventName)
  }
}

export const tracker = new Tracker()

export const useTrackVisitPage = () => {
  const location = useLocation()

  useEffect(() => {
    tracker.track(`[Page View] ${location.pathname}`, {
      search: location.search,
      state: location.state,
    })
  }, [location])
}
