import styled from '@emotion/styled'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'

import { ReactComponent as BackIcon } from './assets/back.svg'
import { SCREENER_COLOR } from './const'

const BackHandlerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 54px;
  align-items: center;
  padding: 0 24px;
`

export const BackHandler = ({
  onBack,
  style,
  color,
}: {
  onBack: any
  style?: any
  color?: string
}) => {
  return (
    <BackHandlerContainer style={style}>
      <MorChanaBackButton onBack={onBack} color={color} />
    </BackHandlerContainer>
  )
}

export const MorChanaBackButton = ({
  onBack,
  color = SCREENER_COLOR.BLACK_TEXT,
}: {
  onBack?: any
  color?: string
}) => {
  const history = useHistory()
  return (
    <button
      style={{
        display: 'flex',
        alignItems: 'center',
        background: 'transparent',
        border: 'none',
      }}
      onClick={onBack || history.goBack}
    >
      <BackIcon fill={color} />
      <span style={{ marginLeft: 16, color, fontFamily: 'Kanit' }}>
        ย้อนกลับ
      </span>
    </button>
  )
}
