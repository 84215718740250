import 'antd-mobile/lib/modal/style/css'
import 'antd-mobile/lib/button/style/css'

import { ScanOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { Button, Modal } from 'antd-mobile'
import React from 'react'

import { useOpenScanner } from '../pages/Scanner/Scanner'

const StyledModal = styled(Modal)`
  .am-modal-content {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
  .am-modal-close {
    top: 20px;
  }
  .am-modal-header {
    padding: 20px 0 0;
  }
`
export const QRCodeSheet = ({ qrCodeUrl, onClose, visible, assessedDate }) => {
  const openScanner = useOpenScanner()

  return (
    <StyledModal
      popup
      visible={visible}
      animationType="slide-up"
      onClose={onClose}
      title="QR Code"
      closable
      className="mm"
    >
      <div className="text-center">
        <img
          src={qrCodeUrl}
          alt="qr code"
          className="mx-auto pt-4 w-3/4"
          style={{ maxWidth: 400 }}
        />
        <div>ประเมิน {assessedDate.format('D MMM YYYY HH:mm')} น.</div>
        <Button
          className=" m-4"
          style={{
            border: '1px solid #DDE3EB',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={openScanner}
        >
          <ScanOutlined style={{ marginRight: 4 }} />
          <span>สแกน</span>
        </Button>
      </div>
    </StyledModal>
  )
}
