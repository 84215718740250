export const shareLINEFlexMessages = async (lineMessages: any) => {
  if (!liff.isLoggedIn()) {
    await liff.login()
    // await liffLoginAndComeback()
  }
  if (Array.isArray(lineMessages)) {
    await liff.shareTargetPicker(lineMessages)
  } else {
    await liff.shareTargetPicker([lineMessages])
  }
}
