import Axios from 'axios'

import { getToken } from './utils/morchana'

const API_URL = process.env.REACT_APP_MORCHANA_API_URL

export const getMorchanaHeader = () => {
  return {
    Authorization: 'Bearer ' + getToken(),
  }
}

export const getQRData = async () => {
  const resp = await Axios(API_URL + `/qr`, {
    method: 'GET',
    headers: getMorchanaHeader(),
  })
  return resp.data
}

export const getTagData = async () => {
  const resp = await Axios(API_URL + `/tags`, {
    method: 'GET',
    headers: getMorchanaHeader(),
  })
  if (resp.status !== 200) {
    throw new Error('Invalid')
  }
  return resp.data
}
