import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment-timezone'
import { useCallback, useEffect } from 'react'
import { screenerMeta } from './screener-meta'

export const REACT_APP_LIFF_ID: string = process.env.REACT_APP_LIFF_ID || ''
const PUBLIC_URL =
  process.env.REACT_APP_PUBLIC_URL || 'https://covid19.cleverse.com'

export const calQuiz = (quizArray) => {
  if (!quizArray || !quizArray.length) return {}
  let stayHomeCombo = 0
  let stayHomeWeekArray = [0, 0, 0, 0, 0, 0, 0]
  let sameRiskLevelCombo = 1
  let startOfWeek = moment().startOf('week')
  const weeks = startOfWeek.weeks()
  const sortedQuiz = _.sortBy(quizArray, 'timestamp').reverse()
  const latestQuiz = sortedQuiz[0]
  if (!latestQuiz.data) return {}
  const { risk_level } = latestQuiz.data
  const length = sortedQuiz.length
  if (latestQuiz.data.int_contact) {
    stayHomeCombo = 1
  }
  stayHomeWeekArray[moment(latestQuiz.timestamp).day()] =
    latestQuiz.data.int_contact
  for (let i = 1; i < length; i++) {
    let prev = sortedQuiz[i - 1]
    let cur = sortedQuiz[i]
    const timestamp = moment(cur.timestamp)
    if (moment(prev.timestamp).isSame(timestamp, 'day')) {
      continue
    }
    if (stayHomeCombo === i && cur.data.int_contact) {
      stayHomeCombo++
    }
    if (sameRiskLevelCombo === i && cur.data.risk_level === risk_level) {
      sameRiskLevelCombo++
    }
    if (timestamp.weeks() === weeks) {
      stayHomeWeekArray[timestamp.day()] = cur.data.int_contact
    }
  }
  return {
    latestQuiz,
    risk_level,
    stayHomeWeekArray,
    stayHomeCombo,
    sameRiskLevelCombo,
  }
}
export const sendNoti = async (quiz) => {
  const { timestamp, data } = quiz
  const { risk_level } = data
  const meta = screenerMeta[risk_level]
  const advices = meta.advices.map((t, i) => `${i + 1}. ${t}`)
  const templateData = {
    date_string: `${moment(timestamp)
      .add(543, 'year')
      .format('D MMM YYYY HH:mm')} น.`,
    risk_level_string: meta.risk_level_string,
    advices,
    advice_note: meta.advice_note,
    image_url: `${PUBLIC_URL}/images/noti-${risk_level}.jpg`,
    button_url: `https://liff.line.me/${REACT_APP_LIFF_ID}/profile`,
  }
  return axios.post(
    `${process.env.REACT_APP_API_URL}/notification`,
    {
      template: 'quiz_result_th',
      params: templateData,
    },
    {
      headers: {
        'X-LINE-ACCESS-TOKEN': liff.getAccessToken(),
        'Content-Type': 'application/json',
      },
    },
  )
}

const setNotificationFrequencySetting = async (frequency) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/notification/setting`,
    {
      type: 'questionaire',
      scheduling: frequency,
    },
    {
      headers: {
        'X-LINE-ACCESS-TOKEN': liff.getAccessToken(),
        'Content-Type': 'application/json',
      },
    },
  )
}

const getNotificationFrequencySetting = async () => {
  const r = await axios.get(
    `${process.env.REACT_APP_API_URL}/notification/setting`,
    {
      headers: {
        'X-LINE-ACCESS-TOKEN': liff.getAccessToken(),
        'Content-Type': 'application/json',
      },
      params: { type: 'questionaire' },
    },
  )
  return _.get(r, 'data.data.scheduling', undefined)
}

const GET_NOTI_FREQUENCY_SETTING = gql`
  {
    notiFrequencySetting @client
  }
`

export const useNotiFrequencySetting = () => {
  const { data, client } = useQuery(GET_NOTI_FREQUENCY_SETTING)

  const _getNotificationFrequencySetting = useCallback(() => {
    getNotificationFrequencySetting().then((frequency) => {
      client.writeData({ data: { notiFrequencySetting: frequency } })
    })
  }, [])
  const _setNotificationFrequencySetting = useCallback((frequency) => {
    setNotificationFrequencySetting(frequency)
    client.writeData({ data: { notiFrequencySetting: frequency } })
  }, [])
  useEffect(() => _getNotificationFrequencySetting(), [])
  return {
    frequency: data.notiFrequencySetting,
    getNotiFrequencySetting: _getNotificationFrequencySetting,
    setNotificationFrequencySetting: _setNotificationFrequencySetting,
  }
}
