import 'antd-mobile/lib/list/style/css'

import { List } from 'antd-mobile'
import { ReactComponent as QRCodeIcon } from 'images/icon-qrcode.svg'
import React from 'react'
import Icon from '@ant-design/icons'
import styled from '@emotion/styled'

export const PADDING_HORIZ = '1rem'
export const StyledQRCode = ({
  size,
  color,
}: {
  size?: number
  color: string
}) => <Icon component={QRCodeIcon} style={{ fontSize: size || 24, color }} />

export const StyledListItem = styled(List.Item)`
  background: white;
  padding: 12px;
  padding-left: ${PADDING_HORIZ};
  padding-right: 1px;
  border-bottom: 1px solid #f0f0f0 !important;
`
