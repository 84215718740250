export const screenerMeta = {
  1: {
    level: 1,
    title: 'ขอบคุณที่ช่วยกันควบคุมโรค',
    description:
      'ช่วยกันลดการแพร่ระบาดด้วยการอยู่บ้าน แต่ถ้าหากมีเหตุจำเป็น ให้อยู่ห่างผู้อื่นอย่างน้อย 2 เมตร และสวมหน้ากากอนามัย',
    risk_level_string: 'ความเสี่ยงระดับต่ำมาก',
    label: 'ความเสี่ยงต่ำมาก',
    advices: ['ล้างมือให้สะอาด', 'สวมหน้ากากอนามัย', 'หลีกเลี่ยงที่แออัด'],
    color: '#2CC26A',
  },
  2: {
    level: 2,
    title: 'นอนหลับพักผ่อนให้เพียงพอ หมอเอาใจช่วย',
    description:
      'ไม่ต้องตกใจ มีความเสี่ยงต่ำที่จะเป็นโควิด-19 คุณอาจจะเป็นโรคอื่น ให้เฝ้าดูอาการ หากอาการป่วยไม่ดี ขึ้นใน 2 วัน แนะนำให้ไปพบแพทย์',
    risk_level_string: 'ความเสี่ยงระดับต่ำ',
    label: 'ความเสี่ยงต่ำ',
    advices: [
      'นอนหลับพักผ่อนให้เพียงพอ',
      'รักษาอาการป่วยให้หาย',
      'ห่างจากคนใกล้ชิดเพื่อป้องกันไว้ก่อน',
    ],
    color: '#B6BA00',
  },
  3: {
    level: 3,
    title: 'ควรกักตัวอยู่ในบ้านให้ครบ 14 วัน',
    description:
      'ควรเฝ้าระวัง แม้คุณจะยังไม่มีอาการป่วย แต่ก็ควรกักตัวอยู่ในบ้าน ให้ห่างจากคนใกล้ชิดเป็นเวลา 14 วัน เพื่อป้องกันการแพร่เชื้อ',
    risk_level_string: 'ความเสี่ยงระดับปานกลาง',
    label: 'ความเสี่ยงปานกลาง',
    advices: [
      'กักตัวอยู่ในบ้านให้ครบ 14 วัน',
      'กักตัวให้ห่างจากคนใกล้ชิด',
      'ถ้ามีอาการป่วย ควรไปโรงพยาบาลทันที',
    ],
    color: '#EF7300',
  },
  4: {
    level: 4,
    title: 'ควรรีบไปโรงพยาบาลทันที',
    description: 'ควรรีบไปโรงพยาบาลทันที',
    risk_level_string: 'ความเสี่ยงระดับสูง',
    label: 'ความเสี่ยงสูง',

    advices: [
      'รีบไปโรงพยาบาลทันที',
      'สวมหน้ากากเพื่อป้องกันการแพร่เชื้อ',
      'หลีกเลี่ยงชุมชน',
    ],
    color: '#E10202',
  },
}
export const screenerMeta1 = [
  {
    level: 1,
    label: 'ความเสี่ยงต่ำมาก',
    description:
      'ช่วยกันลดการแพร่ระบาดด้วยการอยู่ที่บ้าน แต่ถ้าหาก มีเหตุจำเป็น เมื่ออยู่นอกบ้านให้อยู่ห่างผู้อื่นอย่างน้อย 2 เมตร และสวมหน้ากากอนามัย',
    advices: ['ล้างมือให้สะอาด', 'สวมหน้ากากอนามัย', 'หลีกเลี่ยงที่แออัด'],
    color: '#2CC26A',
  },
  {
    level: 2,
    label: 'ความเสี่ยงต่ำ',
    title: 'นอนหลับพักผ่อนให้เพียงพอ หมอเอาใจช่วย',
    description:
      'ไม่ต้องตกใจ มีความเสี่ยงต่ำที่จะเป็นโควิด-19 คุณอาจจะเป็นโรคอื่น ให้เฝ้าดูอาการ หากอาการป่วยไม่ดี ขึ้นใน 2 วัน แนะนำให้ไปพบแพทย์',
    advices: [
      'นอนหลับพักผ่อนให้เพียงพอ',
      'รักษาอาการป่วยให้หาย',
      'ห่างจากคนใกล้ชิดเพื่อป้องกันไว้ก่อน',
    ],
    color: '#B6BA00',
  },
  {
    level: 3,
    label: 'ความเสี่ยงปานกลาง',
    title: 'ควรกักตัวอยู่ในบ้านให้ครบ 14 วัน',
    description:
      'ควรเฝ้าระวัง แม้คุณยังไม่มีอาการป่วย ควรกักตัวอยู่ ในบ้าน ให้ห่างจากคนใกล้ชิดเป็นเวลา 14 วัน เพื่อป้องกันการแพร่เชื้อ',
    advices: [
      'กักตัวอยู่ในบ้านให้ครบ 14 วัน',
      'กักตัวให้ห่างจากคนใกล้ชิด หรือคนในครอบครัว',
      'ถ้ามีอาการป่วย ควรไปโรงพยาบาลทันที',
    ],
    color: '#EF7300',
  },
  {
    level: 4,
    label: 'ความเสี่ยงสูง',
    title: 'ควรรีบไปโรงพยาบาลทันที',
    description: 'ควรรีบไปโรงพยาบาลทันที',
    advices: [
      'รีบไปโรงพยาบาลทันที',
      'สวมหน้ากากเพื่อป้องกันการแพร่เชื้อ',
      'หลีกเลี่ยงชุมชน',
    ],
    color: '#E10202',
  },
]
